.articles-wrapper.grid {
  margin: 0 0 5rem 0;
}

@media screen and (min-width: 750px) {
  .articles-wrapper.grid {
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 749px) {
  .articles-wrapper .article {
    width: 100%;
  }
}

.article {
  display: flex;
  align-items: center;
}

.article.grid__item {
  padding: 0;
}

.article-card {
  background-color: rgba(var(--color-foreground), 0.04);
  align-self: flex-start;
  flex: 0 1 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.grid--peek .article-card {
  box-sizing: border-box;
}

.article-card__info {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.article-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
}

.article-content:hover .article-card__title {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.article-card__image {
  overflow: hidden;
}

.article-content img {
  transition: transform var(--duration-long) ease;
}

.article-content:hover img {
  transform: scale(1.07);
}

.article-card__image-wrapper > a {
  display: block;
}

.article-card__title {
  text-decoration: none;
  word-break: break-word;
}

.article-card__link.link {
  padding: 0;
}

.article-card__link {
  text-underline-offset: 0.3rem;
}

.article-content:hover .article-card__link {
  text-decoration-thickness: 0.2rem;
}

.article-card__header {
  line-height: calc(0.8 / var(--font-body-scale));
  margin-bottom: 1.2rem;
}

.article-card__header h2 {
  margin: 0 0 0.6rem;
}

.article-card__header h2:only-child {
  margin: 0;
}

.article-card__header h2:not(:first-child) {
  margin-top: 1rem;
}

.article-card__header h2 + span {
  display: inline-block;
  margin-top: 0.4rem;
}

.article-card__footer {
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
}

.article-card__footer:not(:last-child) {
  margin-bottom: 1rem;
}

.article-card__footer:last-child {
  margin-top: auto;
}

.article-card__excerpt {
  width: 100%;
}

.article-card__link:not(:only-child) {
  margin-right: 3rem;
}

@media screen and (min-width: 990px) {
  .article-card__link:not(:only-child) {
    margin-right: 4rem;
  }
}

.article-card__image--small {
  padding-bottom: 11rem;
}

.article-card__image--medium {
  padding-bottom: 22rem;
}

.article-card__image--large {
  padding-bottom: 33rem;
}

@media screen and (min-width: 750px) {
  .article-card__image--small {
    padding-bottom: 14.3rem;
  }

  .article-card__image--medium {
    padding-bottom: 21.9rem;
  }

  .article-card__image--large {
    padding-bottom: 27.5rem;
  }
}

@media screen and (min-width: 990px) {
  .article-card__image--small {
    padding-bottom: 17.7rem;
  }

  .article-card__image--medium {
    padding-bottom: 30.7rem;
  }

  .article-card__image--large {
    padding-bottom: 40.7rem;
  }
}
